import { Deserializable } from '../interfaces/deserializable.model';


export class Listino implements Deserializable {
  public id: number;
  id_istanza: number;
  public nome: string;
  public codice: string;
  public prodotti?: any;

  constructor(){
    this.id = 0;
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
